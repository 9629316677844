// Library Import
import React from 'react'
// import { Grid } from '@mui/material'

// File Import
import FooterSocialMobile from './includes/FooterSocial'
import footerStyles from '/styles/scss/footer/Footer.module.scss'
import { cn } from '@/lib/utils'
import FooterMenuLinks from '@/components/layout/includes/mobile-footer/FooterMenuLinks'

const Footer = () => {
  return (
    <footer className={cn(footerStyles['ptm-footer-wr'], 'bg-section-alice-blue')}>
      <section className={cn(footerStyles['ptm-footer-wr_details-and-copyright-section'], '')}>
        <div className={'container px-2.5 md:px-8 lg:px-5'}>
          <div className={'section-spacing'}>
            <div
              className={cn(
                '!m-0 grid grid-cols-1 gap-[30px] !p-0 lg:grid-cols-3 lg:gap-y-[58px] xl:grid-cols-5 xl:gap-6',
                footerStyles['footer-details-section-wr']
              )}
              // container
              // justifyContent='space-center'
              // className={footerStyles['footer-details-section-wr']}
              // columnSpacing={{ xs: 0, sm: 0, md: 7.5 }}
              // rowSpacing={{ xs: 7.3, sm: 7.3, md: 0 }}
            >
              <FooterMenuLinks />
            </div>
          </div>
          <div className='lg:hidden xl:block'>
            <FooterSocialMobile />
          </div>
        </div>
      </section>
      <section className={footerStyles['ptm-footer-wr_disclaimer-section']}>
        <div
          className={
            'lg:px-auto flex items-center justify-center bg-[#38366d] px-6 pb-[36px] pt-[36px] text-white md:px-[52px] md:py-[30px] lg:py-[20px] xl:px-0 xl:py-[20px]'
          }
        >
          <div className={'container p-0'}>
            <div className={`flex flex-col items-start justify-center`}>
              <p className={'mb-2 !text-left text-lg font-semibold md:text-xl'}>
                PayToMe.co (Pay To Me Inc.) is a financial technology company and is{' '}
                <span className={'font-extrabold underline'}>not a bank</span>, Banking services provide by our sponsor
                banks.
              </p>
              <p className={'!text-left text-lg font-semibold md:text-xl'}>
                PayToMe.co (Pay To Me Inc.) is a registered ISO/MSP of Elavon Inc. (
                <a href={`https://www.elavon.com`} className={'font-extrabold !underline'} target={'_blank'}>
                  www.elavon.com
                </a>
                )
              </p>
              {/*<p>&#169; {new Date().getFullYear()} PayToMe.co. Registered Payment Facilitator. All Rights Reserved</p>*/}
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
