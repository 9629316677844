// @ts-ignore
import textToPay from '~/public/assets/images/top-menu/text-to-pay.svg'
// @ts-ignore
import eCheck from '~/public/assets/images/top-menu/e-check.svg'
// @ts-ignore
import kybVerification from '~/public/assets/images/top-menu/kyb-verification.svg'
// @ts-ignore
import paymentLinks from '~/public/assets/images/top-menu/payment-links.svg'
// @ts-ignore
import achTransfer from '~/public/assets/images/top-menu/ach-transfer.svg'
// @ts-ignore
import bankVerification from '~/public/assets/images/top-menu/bank-verification.svg'
// @ts-ignore
import onlineInvoicing from '~/public/assets/images/top-menu/online-invoicing.svg'
// @ts-ignore
import debitAndCreditCard from '~/public/assets/images/top-menu/debit-and-credit-card.svg'
// @ts-ignore
import freeInvoiceGenerator from '~/public/assets/images/top-menu/free-invoice-generator.svg'
// @ts-ignore
import onlineCheckbook from '~/public/assets/images/top-menu/online-checkbook.svg'
// @ts-ignore
import kycVerification from '~/public/assets/images/top-menu/kyc-verification.svg'

// @ts-ignore
import accountReceivable from '~/public/assets/images/top-menu/solutions/account-receivable.svg'
// @ts-ignore
import identityVerification from '~/public/assets/images/top-menu/solutions/identity-verification.svg'
// @ts-ignore
import nonprofitDonations from '~/public/assets/images/top-menu/solutions/nonprofit-donations-0-fee.svg'
// @ts-ignore
import accountPayable from '~/public/assets/images/top-menu/solutions/account-payable.svg'
// @ts-ignore
import merchantServices from '~/public/assets/images/top-menu/solutions/merchant-services.svg'
// @ts-ignore
import globalMoneyMovement from '~/public/assets/images/top-menu/solutions/global-money-movement.svg'
// @ts-ignore
import expenseManagement from '~/public/assets/images/top-menu/solutions/expense-management.svg'
// @ts-ignore
import eCommerceCashPayment from '~/public/assets/images/top-menu/solutions/e-commerce-cash-payment.svg'
// @ts-ignore
import businessBanking from '~/public/assets/images/top-menu/solutions/business-banking.svg'

// @ts-ignore
import inPersonPayments from '~/public/assets/images/top-menu/merchant-services/in-person-payments.svg'
// @ts-ignore
import onlinePayments from '~/public/assets/images/top-menu/merchant-services/online-payments.svg'
// @ts-ignore
import fixMonthlyMerchantFee from '~/public/assets/images/top-menu/merchant-services/fix-monthly-merchant-fee.svg'
// @ts-ignore
import retailPointOfSalePos from '~/public/assets/images/top-menu/merchant-services/retail-point-of-sale-pos.svg'
// @ts-ignore
import virtualCreditCardTerminal from '~/public/assets/images/top-menu/merchant-services/virtual-credit-card-terminal.svg'
// @ts-ignore
import interchangeMerchantFee from '~/public/assets/images/top-menu/merchant-services/interchange-merchant-fee.svg'
// @ts-ignore
import creditCardTerminal from '~/public/assets/images/top-menu/merchant-services/credit-card-terminal.svg'
// @ts-ignore
import merchantPricing from '~/public/assets/images/top-menu/merchant-services/merchant-pricing.svg'
// @ts-ignore
import merchantFee from '~/public/assets/images/top-menu/merchant-services/0-merchant-fee.svg'
// @ts-ignore
import mobileCreditCardReaders from '~/public/assets/images/top-menu/merchant-services/mobile-credit-card-readers.svg'
// @ts-ignore
import fixRateMerchantFee from '~/public/assets/images/top-menu/merchant-services/fix-rate-merchant-fee.svg'
// @ts-ignore
import openMerchantAccount from '~/public/assets/images/top-menu/merchant-services/open-merchant-account.svg'

// @ts-ignore
import aboutPayToMeCo from '~/public/assets/images/top-menu/company/about-paytomeco.svg'
// @ts-ignore
import boardOfDirectors from '~/public/assets/images/top-menu/company/board-of-directors.svg'
// @ts-ignore
import contactUs from '~/public/assets/images/top-menu/company/contact-us.svg'
// @ts-ignore
import newsAndPress from '~/public/assets/images/top-menu/company/news-and-press.svg'
// @ts-ignore
import partners from '~/public/assets/images/top-menu/company/partners.svg'
// @ts-ignore
import careers from '~/public/assets/images/top-menu/company/careers.svg'

// @ts-ignore
import pricingForMerchant from '~/public/assets/images/top-menu/pricing/pricing-for-merchant.svg'
// @ts-ignore
import pricingForPlatforms from '~/public/assets/images/top-menu/pricing/pricing-for-platforms.svg'
import { ROUTES } from '@/v1/constants'

export const MainMenus = [
  {
    id: 1,
    name: 'Product',
    url: '/product',
    label: 'Products & Services',
    content:
      'Our diverse range of products designed to streamline your payments and enhance your financial management effortlessly.',
    children: [
      {
        id: 1,
        url: '/text-to-pay',
        icon: textToPay,
        label: 'Text To Pay',
        content: 'Send invoice by (SMS) <br/>  Text Messaging'
      },
      // {
      //   id: 2,
      //   url: '#',
      //   icon: eCheck,
      //   label: 'E-Check',
      //   content: 'Pay with a secure E-Check and <br/> ensure convenient payment'
      // },
      // {
      //   id: 4,
      //   url: '/payment-link',
      //   icon: paymentLinks,
      //   label: 'Payment Links',
      //   content: 'Create link for payment <br/> in minutes and send by text'
      // },
      // {
      //   id: 5,
      //   url: '/ach-transfer',
      //   icon: achTransfer,
      //   label: 'ACH Transfer',
      //   content: 'Bank to bank instant <br/> money transfer'
      // },
      {
        id: 7,
        url: '/online-invoicing',
        icon: onlineInvoicing,
        label: 'Online Invoicing',
        content: 'Create an invoice in seconds and <br/> send through text & email'
      },
      // {
      //   id: 8,
      //   url: '/debit-credit-card',
      //   icon: debitAndCreditCard,
      //   label: 'Debit & Credit Card',
      //   content: 'Create your own debit card <br/> for expense management'
      // },
      {
        id: 9,
        url: '/invoice-generator',
        icon: freeInvoiceGenerator,
        label: 'Free Invoice Generator',
        content: 'Create and send free digital <br/> invoice worldwide'
      },
      {
        id: 10,
        url: '/online-checkbook',
        icon: onlineCheckbook,
        label: 'Online Checkbook',
        content: 'Write online checkbook and send <br/> it through regular mail or FedEx'
      },
      {
        id: 11,
        url: '/kyc-verification',
        icon: kycVerification,
        label: 'KYC Verification',
        content: 'Verify your users through <br/> Know Your Customer'
      },
      {
        id: 12,
        url: '/kyb-verification',
        icon: kybVerification,
        label: 'KYB Verification',
        content: 'Know your Business <br/> Customer & Vendor Businesses'
      },
      {
        id: 13,
        url: '/bank-account-verification',
        icon: bankVerification,
        label: 'Bank Verification',
        content: 'Verify bank account information <br/> owner info & account balance'
      }
    ]
  },
  {
    id: 2,
    name: 'Solution',
    url: '/solution',
    label: 'Solutions and Platforms',
    content:
      'Explore our range of solutions, specifically designed for businesses in all sectors to enhance operational efficiency and financial management.',
    children: [
      {
        id: 1,
        url: '/accounts-receivables',
        icon: accountReceivable,
        label: 'Account Receivable',
        content: 'Manage account receivables <br/> efficiently'
      },
      // {
      //   id: 2,
      //   url: '#',
      //   icon: identityVerification,
      //   label: 'Identity Verification',
      //   content: 'Verify users and bank account <br/> ownership easily'
      // },
      // {
      //   id: 3,
      //   url: '#',
      //   icon: nonprofitDonations,
      //   label: 'Nonprofit Donations (0 Fee)',
      //   content: 'Nonprofit organizations can accept <br/> donations without any fee'
      // },
      {
        id: 4,
        // url: '/accounts-payable',
        url: '/accounts-payable-services',
        icon: accountPayable,
        label: 'Account Payable',
        content: 'Ensure timely payment of bills and <br/> effective vendor management'
      },
      {
        id: 5,
        url: '/merchant-services',
        icon: merchantServices,
        label: 'Merchant Services',
        content: 'Accept payment online <br/> or in-person'
      },
      // {
      //   id: 6,
      //   url: '#',
      //   icon: globalMoneyMovement,
      //   label: 'Global Money Movement',
      //   content: 'Pay your overseas customer <br/> fast and with low fees'
      // },
      {
        id: 7,
        url: '/expense-management',
        icon: expenseManagement,
        label: 'Expense Management',
        content: 'Easily track and manage <br/> spending'
      }
      // {
      //   id: 8,
      //   url: '#',
      //   icon: eCommerceCashPayment,
      //   label: 'E-Commerce Cash Payment',
      //   content: 'Users are allowed to purchase online <br/> with cash payment'
      // },
      // {
      //   id: 9,
      //   url: '#',
      //   icon: businessBanking,
      //   label: 'Business Banking',
      //   content: 'Open an account in a minute for  <br/> online business banking'
      // }
    ]
  },
  {
    id: 3,
    name: 'Merchant Services',
    url: '/merchant-services',
    label: 'Merchant Services',
    content:
      'Enhance your sales with our Merchant Services, offering secure and efficient payment processing solutions tailored to your business needs.',
    children: [
      // {
      //   id: 1,
      //   url: '#',
      //   icon: inPersonPayments,
      //   label: 'In-Person Payments',
      //   content: 'Small and medium <br/> retail payment solution'
      // },
      {
        id: 2,
        url: ROUTES.ONLINE_PAYMENTS,
        icon: onlinePayments,
        label: 'Online Payments',
        content: 'E-Commerce solution with <br/> free technical support'
      },
      // {
      //   id: 3,
      //   url: '#',
      //   icon: fixMonthlyMerchantFee,
      //   label: 'Fix Monthly Merchant Fee',
      //   content: 'Pay fixed $79<span class="text-red-strong">*</span> monthly fee <br/> + interchange cost'
      // },
      {
        id: 4,
        url: '/retails-pos-system',
        icon: retailPointOfSalePos,
        label: 'Retail Point-of-Sale (POS)',
        content: 'All in one Retail POS <br/>Credit Card System'
      }
      // {
      //   id: 5,
      //   url: '#',
      //   icon: virtualCreditCardTerminal,
      //   label: 'Virtual Credit Card Terminal',
      //   content: 'Take credit card payments online <br/>using a tablet, phone, or computer'
      // },
      // {
      //   id: 6,
      //   url: '#',
      //   icon: interchangeMerchantFee,
      //   label: 'Interchange + Merchant Fee',
      //   content: 'Pay interchange cost + <br/>Low as + 0.25%<span class="text-red-strong">*</span> fees'
      // },
      // {
      //   id: 7,
      //   url: '#',
      //   icon: creditCardTerminal,
      //   label: 'Credit Card Terminal',
      //   content: 'Wireless and remote <br/>Credit card terminal'
      // },
      // {
      //   id: 8,
      //   url: '#',
      //   icon: merchantPricing,
      //   label: 'Merchant Pricing',
      //   content: 'Straightforward credit card <br/>merchant pricing'
      // },
      // {
      //   id: 9,
      //   url: '#',
      //   icon: merchantFee,
      //   label: '0% Merchant Fee',
      //   content: 'You can add a credit card <br/>for merchant fee on customer bill'
      // },
      // {
      //   id: 10,
      //   url: '#',
      //   icon: mobileCreditCardReaders,
      //   label: 'Mobile Credit Card Readers',
      //   content: 'Portable, efficient, wireless <br/>mobile on-the-go card readers'
      // },
      // {
      //   id: 10,
      //   url: '#',
      //   icon: fixRateMerchantFee,
      //   label: 'Fix rate Merchant Fee',
      //   content:
      //     '2.75 %<span class="text-red-strong">*</span> In-Person fee <br/>3.15 %<span class="text-red-strong">*</span> Online fee'
      // },
      // {
      //   id: 11,
      //   url: '#',
      //   icon: openMerchantAccount,
      //   label: 'Open Merchant Account',
      //   content: 'Apply online and approve <br/>In minutes'
      // }
    ]
  },
  {
    id: 4,
    name: 'Company',
    url: '/company',
    label: 'Company',
    content: 'Learn about our values and team driving innovation and customer satisfaction.',
    children: [
      {
        id: 1,
        url: '/about-us',
        icon: aboutPayToMeCo,
        label: 'About PayToMe.co',
        content: 'Our story started in 2023 to <br/> revolutionize online payments'
      },
      {
        id: 2,
        url: '/board-of-directors',
        icon: boardOfDirectors,
        label: 'Board of Directors',
        content: 'Meet with our <br/> board and teams'
      },
      {
        id: 3,
        url: '/contact-us',
        icon: contactUs,
        label: 'Contact Us',
        content: 'We are open 24/7 <br/> Online chat or phone'
      },
      {
        id: 4,
        url: '/news',
        icon: newsAndPress,
        label: 'News & Press',
        content: 'Follow us on the news <br/> and press release'
      }
      // {
      //   id: 5,
      //   url: '/partners',
      //   icon: partners,
      //   label: 'Partners',
      //   content: 'Plaid, Stripe, Elavon <br/> AppTech'
      // }
      // {
      //   id: 6,
      //   url: '/careers',
      //   icon: careers,
      //   label: 'Careers',
      //   content: 'Join PayToMe team <br/> Submit application online'
      // }
    ]
  },
  {
    id: 5,
    name: 'Pricing',
    url: '/pricing',
    label: 'Pricing',
    content: 'Explore our transparent pricing for affordable, value-driven solutions.',
    wrapperClass: `max-w-[1178px]`,
    childGridClass: `grid-cols-2`,
    children: [
      {
        id: 1,
        url: '/pricing',
        icon: pricingForMerchant,
        label: 'Pricing For Merchant',
        content: 'Clear, straightforward <br/> merchant pricing policies'
      },
      {
        id: 2,
        url: '/pricing',
        icon: pricingForPlatforms,
        label: 'Pricing For Platforms',
        content: 'Our platforms are designed to use <br/> for free or low cost for users'
      }
    ]
  }
]
